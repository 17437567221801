import { Store } from '@/store/pinia-class-component';
import { miniEntityStore } from '@/store/entities/types/miniEntityStore';
import { InstallationPoint } from '@/models/installationPoint/InstallationPoint';
import { getAllEntitiesByCriteria } from '@/store/entities/helper';
import { useDeviceStore } from '@/store/entities/deviceStore';
import { Tag } from '@/utils/canvas/drawingUtils';
import { DeviceTypeEnum, deviceTypes } from '@/enums/device';
import { DeviceClasses } from '@/models/devices/Device';
import { InspectionPoint } from '@/models/inspectionPoint/InspectionPoint';
import { useInspectionDeviceStore } from '@/store/entities/inspectionDeviceStore';
import { OverallRadioCheckStatus } from '@/enums/radiocheck';
import { useOrderStore } from '@/store/order/orderStore';
import { useInstallationPointStore } from '@/store/entities/installationPointStore';
import { useEntitiesStore } from '@/store/entities/entitiesStore';

export function useInspectionPointStore() {
  return new InspectionPointStore();
}

@Store
export class InspectionPointStore extends miniEntityStore {
  private _inspectionPoints: Map<string, InspectionPoint>;

  constructor() {
    super();
    this._inspectionPoints = new Map();
  }

  get inspectionPoints(): Map<string, InspectionPoint> {
    return this._inspectionPoints;
  }

  getAllActiveInspectionPoints(): InspectionPoint[] {
    return getAllEntitiesByCriteria(
      this._inspectionPoints,
      (entity: InspectionPoint) =>
        useOrderStore().getOrderChanges()?.transactions[entity.id]
    );
  }

  getAllActiveTestDevices() {
    const activeDeviceIds = new Set<string>();
    this._inspectionPoints.forEach((inspectionPoint) => {
      if (inspectionPoint.activeTestDeviceId) {
        activeDeviceIds.add(inspectionPoint.activeTestDeviceId);
      }
    });
    const devices: DeviceClasses[] = [];
    useDeviceStore()
      .getDevices()
      .forEach((device) => {
        if (activeDeviceIds.has(device.id)) {
          devices.push(device);
        }
      });
    return devices;
  }

  getTestDevicesByRoomId(roomId: string) {
    const activeDeviceIds = new Set<string>();
    this.getAllActiveInspectionPoints().forEach((inspectionPoint) => {
      if (
        inspectionPoint.activeTestDeviceId &&
        inspectionPoint.roomId === roomId
      ) {
        activeDeviceIds.add(inspectionPoint.activeTestDeviceId);
      }
    });
    const devices: DeviceClasses[] = [];
    useInspectionDeviceStore().inspectionDevices.forEach((device) => {
      if (activeDeviceIds.has(device.id)) {
        devices.push(device);
      }
    });
    return devices;
  }

  getInspectionPointsByParentId(parentId: string): InspectionPoint[] {
    return getAllEntitiesByCriteria(
      this.getAllActiveInspectionPoints(),
      (entity: InspectionPoint) => {
        return entity.getParentId() === parentId;
      }
    );
  }

  getCompletedGatewaysByRoomGroupId(roomGroupId: string): InspectionPoint[] {
    const installationPoints =
      useInstallationPointStore().getInstallationPointsByRoomGroupId(
        roomGroupId
      );

    if (installationPoints.length > 0) return [];
    return getAllEntitiesByCriteria(
      this._inspectionPoints,
      (entity: InspectionPoint) => {
        const room = useEntitiesStore().getEntityById(entity.roomId);
        return (
          entity.supportedDeviceType === deviceTypes.LoRaWanGateway &&
          entity.inspectionData?.radioCheckResult ===
            OverallRadioCheckStatus.COMPLETED &&
          room.roomGroupId === roomGroupId
        );
      }
    );
  }

  getCompletedGatewaysByRoomId(roomId: string): InspectionPoint[] {
    const installationPoints =
      useInstallationPointStore().getInstallationPointsByRoomId(roomId);

    if (installationPoints.length > 0) return [];
    return getAllEntitiesByCriteria(
      this._inspectionPoints,
      (entity: InspectionPoint) =>
        entity.supportedDeviceType === deviceTypes.LoRaWanGateway &&
        entity.inspectionData?.radioCheckResult ===
          OverallRadioCheckStatus.COMPLETED &&
        entity.getParentId() === roomId
    );
  }

  getInspectionPointsByRoomGroupId(roomGroupId: string): InspectionPoint[] {
    return getAllEntitiesByCriteria(
      this.getAllActiveInspectionPoints(),
      (entity: InspectionPoint) => {
        const room = useEntitiesStore().getEntityById(entity.roomId);
        return room.roomGroupId === roomGroupId;
      }
    );
  }

  getInspectionPointsByRoomId(roomId: string): InstallationPoint[] {
    return getAllEntitiesByCriteria(
      this.getAllActiveInspectionPoints(),
      (entity: InstallationPoint) => entity.roomId === roomId
    );
  }

  addEntity(inspectionPoint: InspectionPoint): void {
    const tryJSONParse = (value: unknown) => {
      if (typeof value === 'string') {
        try {
          return JSON.parse(value);
        } catch (error) {
          console.error('Invalid JSON string', error);
        }
      }
      return value;
    };
    inspectionPoint.inspectionData?.radioChecks?.forEach((radioCheck) => {
      radioCheck.result = tryJSONParse(radioCheck.result);
    });
    this._inspectionPoints.set(inspectionPoint.id, inspectionPoint);
  }

  isInspectionDeletable(id: string) {
    const installationPoint = this._inspectionPoints.get(id);
    if (!installationPoint) {
      throw new Error('Installation point not found');
    }
    const devices = useDeviceStore().getDevicesByInstallationPointId(id);
    return devices.length <= 1;
  }

  deleteEntity(entity: InstallationPoint): void {
    const devices = useDeviceStore().getDevicesByInstallationPointId(entity.id);
    devices.forEach((device) => {
      useDeviceStore().deleteEntity(device);
    });
    this._inspectionPoints.delete(entity.id);
  }

  getTagsOfRoomgroup(roomgroupId: string): Record<string, Array<Tag>> {
    const listOfTags: Record<string, any> = { ACTIVE: [], INACTIVE: [] };

    const inspectionsPoints =
      this.getInspectionPointsByRoomGroupId(roomgroupId);

    const SRTDevices = inspectionsPoints.filter(
      (ip) => ip.supportedDeviceType === deviceTypes.SmartRadiatorThermostat
    );

    const LoRaWanDevice = inspectionsPoints.filter(
      (ip) => ip.supportedDeviceType === deviceTypes.LoRaWanGateway
    );

    if (SRTDevices.length > 0) {
      listOfTags.ACTIVE.push(
        new Tag(
          SRTDevices[0].getLabel() as string,
          SRTDevices.length,
          SRTDevices[0].getTagColor() as string
        )
      );
    }

    if (LoRaWanDevice.length > 0) {
      listOfTags.ACTIVE.push(
        new Tag(
          LoRaWanDevice[0].getLabel(),
          LoRaWanDevice.length,
          LoRaWanDevice[0].getTagColor() as string
        )
      );
    }

    return listOfTags;
  }

  getInspectionPointStatus(payload: {
    roomGroupId?: string;
    roomId?: string;
  }): OverallRadioCheckStatus[] {
    const inspectionPoints = payload.roomGroupId
      ? this.getInspectionPointsByRoomGroupId(payload.roomGroupId)
      : payload.roomId
      ? this.getInspectionPointsByRoomId(payload.roomId)
      : [];

    return inspectionPoints
      .filter(
        (ip) => ip.supportedDeviceType === DeviceTypeEnum.LO_RA_WAN_GATEWAY
      )
      .map((gateway) => gateway.inspectionData?.radioCheckResult);
  }

  resetState(): void {
    this._inspectionPoints = new Map();
  }
}
